import React, { useState, createContext, useCallback, useMemo } from 'react';

export const DialogContext = createContext();

export const DialogProvider = props => {
  const { children } = props;
  const [dialogs, setComponents] = useState([]);

  const pushDialog = useCallback(
    dialog => {
      setComponents([...dialogs, dialog]);
    },
    [dialogs],
  );

  const popDialog = useCallback(() => {
    dialogs.pop();
    setComponents([...dialogs]);
  }, [dialogs]);

  const value = useMemo(
    () => ({ pushDialog, popDialog }),
    [pushDialog, popDialog],
  );

  return (
    <DialogContext.Provider value={value}>
      {children}
      {dialogs.map(([component, componentProps], index) => {
        return React.createElement(component, {
          popDialog,
          key: index,
          ...componentProps,
        });
      })}
    </DialogContext.Provider>
  );
};
