import { useUser } from 'hooks';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const AuthedRoute = () => {
  const { user } = useUser();

  // useEffect(() => {
  //   if (user?.authenticated !== true) {
  //     return;
  //   }
  //   // TODO: This needs to go into WaitForConfig.jsx instead, OR be
  //   //  completely replaced by defaultAppConfig.json in the backend
  //   //  on a later ticket.
  //   getProtectedConfig().then(protectedConfig => {
  //     if (!protectedConfig) {
  //       throw new Error('Empty configuration object returned from API');
  //     }
  //     setStore(s => ({
  //       ...s,
  //       config: { ...s.config, ...protectedConfig },
  //     }));
  //   });
  // }, [user, setStore]);

  if (user.authenticated !== true) {
    return <Navigate to={'/login'} />;
  }

  return <Outlet />;
};
