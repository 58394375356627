import {
  useCallback,
  useEffect,
  useState,
  createContext,
  useMemo,
} from 'react';
import { SIZE } from 'utils/device';

export const StoreContext = createContext();

const initState = {
  facetsInclude: [],
  facetsExclude: [],
  pageSize: 10,
  currentPage: 1,
  config: {},
  publicConfigLoaded: false,
};

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState(initState);

  const onResize = useCallback(() => {
    const width = window.innerWidth;
    const isMobile = width <= SIZE.mobile;
    const isTablet = width <= SIZE.tablet;
    const viewPort = { width, height: window.innerHeight };

    setStore(s => ({
      ...s,
      viewPort,
      isMobile,
      isTablet,
    }));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onResize]);

  useEffect(() => {
    const timer = setTimeout(() => {
      onResize();
    }, 300);

    return () => clearTimeout(timer);
  }, [onResize]);

  const value = useMemo(() => ({ store, setStore }), [store]);

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
};
