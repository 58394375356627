import { useEffect, useState, createContext, useMemo } from 'react';

const TimeoutAlertContext = createContext();

const TimeoutAlertProvider = ({ children }) => {
  const [transactionOccurring, setTransactionOccurring] = useState(false);
  const [displayContacting, setDisplayContacting] = useState(false);
  const [displayUnavailable, setDisplayUnavailable] = useState(false);

  useEffect(() => {
    //Begin with alerts turned off. On a new call, we assume that we need to turn alerts off again.
    setDisplayContacting(false);
    setDisplayUnavailable(false);

    if (transactionOccurring === true) {
      setTimeout(() => {
        if (transactionOccurring === true) {
          setDisplayContacting(true);
          setTimeout(() => {
            if (transactionOccurring === true) {
              setDisplayContacting(false);
              setDisplayUnavailable(true);
              setTimeout(() => {
                setDisplayUnavailable(false);
              }, 10000);
            } else {
              setDisplayContacting(false);
              setDisplayUnavailable(false);
              return;
            }
          }, 10000);
        } else {
          setDisplayContacting(false);
          setDisplayUnavailable(false);
          return;
        }
      }, 3000);
    } else return;
  }, [transactionOccurring]);

  const value = useMemo(
    () => ({
      transactionOccurring,
      displayContacting,
      displayUnavailable,
      setTransactionOccurring,
      setDisplayContacting,
      setDisplayUnavailable,
    }),
    [transactionOccurring, displayContacting, displayUnavailable],
  );

  return (
    <TimeoutAlertContext.Provider value={value}>
      {children}
    </TimeoutAlertContext.Provider>
  );
};

export { TimeoutAlertContext, TimeoutAlertProvider };
