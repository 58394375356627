import { fetchBackend } from 'utils/apiUtils';

export const getPublicConfig = async ({ forceRefresh = false } = {}) => {
  let url = '/api/config';
  if (forceRefresh) {
    url += `?forceRefresh=true`;
  }
  return fetchBackend(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(resp => {
      if (resp.ok) return resp.json();
      throw new Error('Failed to get config parameters');
    })
    .catch(err => {
      throw err;
    });
};

export const updatePublicConfig = data => {
  return fetchBackend(`/api/appManagement/`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  })
    .then(resp => {
      if (resp.ok) return resp.blob();
      throw new Error(`Failed to update appConfig`);
    })
    .catch(err => {
      throw err;
    });
};

export const updatePublicConfigLogo = (body, isLogo) => {
  return fetchBackend(
    `/api/appManagement/logo?${new URLSearchParams({
      isLogo,
    })}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      body: body,
    },
  )
    .then(resp => {
      if (resp.ok) return resp.blob();
      throw new Error(`Failed to update appConfig logo`);
    })
    .catch(err => {
      throw err;
    });
};

export const getConfigAsset = async filename => {
  return fetchBackend(
    `/api/config/assets?filename=${encodeURIComponent(filename)}`,
    {
      method: 'GET',
    },
  )
    .then(resp => {
      if (resp.ok) return resp.blob();
      throw new Error(`Failed to get asset '${filename}'`);
    })
    .catch(err => {
      throw err;
    });
};

export const checkConfigAsset = async filename => {
  return (
    fetchBackend(
      `/api/config/assets?filename=${encodeURIComponent(filename)}`,
      {
        method: 'HEAD',
      },
    )
      // 'true' indicates "the document is in the database", while 'false'
      //   indicates the opposite. Anything else is a genuine error.
      .then(resp => {
        if (resp.ok) return true;
        else if (resp.status === 404) return false;
        throw new Error(`Failed to check for asset '${filename}'`);
      })
      .catch(err => {
        throw err;
      })
  );
};

export const getProtectedConfig = async ({ forceRefresh = false } = {}) => {
  let url = '/api/config/protected';
  if (forceRefresh) {
    url += `?forceRefresh=true`;
  }
  return fetchBackend(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(resp => {
      if (resp.ok) return resp.json();
      throw new Error('Failed to get config parameters');
    })
    .catch(err => {
      throw err;
    });
};
