import { getCurrentUser } from 'apis/user';
import React, {
  useState,
  createContext,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [previousUrl, setPreviousUrl] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let ignore = false;
    const fn = async () => {
      const data = await getCurrentUser();
      if (ignore) return;
      if (data) {
        setUser(data);
      }
      setLoading(false);
    };

    fn();

    return () => {
      ignore = true;
    };
  }, []);

  const navigate = useNavigate();

  const logout = useCallback(
    (redirect = `/login`) => {
      setPreviousUrl('');
      setUser({});
      navigate(redirect);
    },
    [navigate],
  );

  const value = useMemo(
    () => ({
      user,
      setUser,
      logout,
      previousUrl,
      setPreviousUrl,
    }),
    [logout, previousUrl, user],
  );

  if (loading) return null;

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserContext, UserProvider };
